import React from 'react';
import { Helmet } from 'react-helmet';
import GlobalFonts from './GlobalFonts';
import Reset from './Reset';
import { createGlobalStyle } from 'styled-components';
import 'what-input';
import { FontFamily, Color } from '../Settings';
import { rem } from 'polished';
import GridOverlay from './GridOverlay';
import { Viewport } from '../Settings';

const Globals = createGlobalStyle`
    :root {
        --jade: ${Color.Jade};
        --jadeWhite: ${Color.JadeWhite};
        --blue: ${Color.Blue};
        --white: ${Color.White};
        --black: ${Color.Black};
        --gray: ${Color.Gray};

        --size1: ${rem(8)};
        --size2: ${rem(16)};
        --size3: ${rem(24)};
        --size4: ${rem(30)};
        --size5: ${rem(40)};
        --size6: ${rem(60)};
        --size7: ${rem(74)};
        --size8: ${rem(100)};
        --size9: ${rem(140)};
        --size10: ${rem(160)};
        --size11: ${rem(200)};
        --sizeGutters: calc(100vw / 24);

        --lineHeightMin: 1;
        --lineHeightMed: 1.2;
        --lineHeightMax: 1.5;

        --letterSpacingExtra: calc(2.6 / 16 * 1em);

        --backgroundColor: var(--white);
        --foregroundColor: var(--blue);
        --highlightColor: var(--jade);
        font-family: ${JSON.stringify(
            FontFamily.CircularStd
        )}, "Trebuchet MS", Arial, sans-serif;;

        --sectionSpacing10: var(--size10);
        --sectionSpacing11: var(--size11);

        @media (max-width: ${Viewport.TabletPortrait}px) {
            --sectionSpacing10: var(--size9);
            --sectionSpacing11: var(--size10);
        }

        @media (max-width: ${Viewport.Mobile}px) {
            --sectionSpacing10: var(--size6);
            --sectionSpacing11: var(--size7);
        }
    }

    html,
    body {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        background-color: var(--orange);
    }

    body {
        //
    }

    // Box sizing
    *,
    *:before,
    *:after {
        box-sizing: border-box;
    }

    // Android applies some styles to buttons by default.
    button {
        background: none;
        appearance: none;
        border: 0;
        padding: 0;
        margin: 0;
    }

    button,
    [role='button'] {
        cursor: pointer;
    }

    // Used in combo with 'what-input'. Prevents outlines unless focus was applied by keyboard navigability.
    [data-whatinput='mouse'] :focus,
    [data-whatinput='touch'] :focus {
        outline: none;
    }

    a {
        text-decoration-color: currentColor;
        text-decoration: none;
    }
`;

interface LayoutProps {
    children: React.ReactNode;
}

function Layout({ children }: LayoutProps): React.ReactElement {
    return (
        <React.Fragment>
            <Helmet>
                <html lang="en" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0"
                />
            </Helmet>
            <Reset />
            <GlobalFonts />
            <Globals />
            {children}
            {process.env.NODE_ENV !== 'production' && <GridOverlay />}
        </React.Fragment>
    );
}

export default React.memo(Layout);
