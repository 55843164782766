import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    display: grid;
    grid-template-columns: repeat(24, minmax(0, 1fr));
`;

const Column = styled.div`
    outline: 1px solid black;

    &:nth-child(odd) {
        background-color: rgba(0, 0, 0, 0.2);
    }
`;

const Hr = styled.div`
    border-top: 1px solid black;
    position: fixed;
    left: 0;
    width: 100%;
    height: 1px;
    top: var(--mouse-y);
`;

function GridOverlay(): React.ReactElement | null {
    const [isShown, setIsShown] = React.useState<boolean>(false);

    React.useEffect((): (() => void) | void => {
        if (isShown) {
            const onMouseMove = (e: MouseEvent): void => {
                document.documentElement.style.setProperty(
                    '--mouse-y',
                    e.clientY + 'px'
                );
            };

            window.addEventListener('mousemove', onMouseMove, false);

            return (): void =>
                window.removeEventListener('mousemove', onMouseMove);
        }
    }, [isShown]);

    React.useEffect((): (() => void) => {
        console.log('Press Ctrl+Alt+Shift+g to toggle the Grid Overlay');
        const onKeyPress = (event: KeyboardEvent): void => {
            if (typeof event.getModifierState === 'undefined') {
                // When using autocomplete event.getModifierState doesn't exist.
                return;
            }
            const shiftPressed = event.getModifierState('Shift');
            const ctrlPressed = event.getModifierState('Control');
            const altPressed = event.getModifierState('Alt');
            const gPressed =
                event.keyCode === 71 ||
                event.key === 'g' ||
                event.code === 'KeyG';

            if (shiftPressed && ctrlPressed && altPressed && gPressed) {
                event.preventDefault();
                setIsShown((state): boolean => !state);
            }
        };
        window.addEventListener('keydown', onKeyPress);

        return (): void => window.removeEventListener('keydown', onKeyPress);
    }, [setIsShown]);

    return isShown ? (
        <Container>
            <Hr />
            {Array.from(Array(24)).map(
                (el, index): React.ReactElement => (
                    <Column key={index} />
                )
            )}
        </Container>
    ) : null;
}

export default React.memo(GridOverlay);
