export enum FontFamily {
    CircularStd = 'CircularStd',
}

export enum Color {
    Jade = '#19998c',
    JadeWhite = '#9dded7',
    Blue = '#243482',
    White = '#ffffff',
    Black = '#000000',
    Gray = '#a1a1a1',
}

export enum Viewport {
    Mobile = 420,
    TabletPortrait = 768,
    TabletLandscape = 1024,
    Netbook = 1366,
    Desktop = 1680,
    Workstation = 2560,
}

export const FACEBOOK_URL = 'https://www.facebook.com/jazzaotearoa/';

export const CONTACT_EMAIL = 'jazzaotearoa@gmail.com';

export const CONTACT_FORM_NAME = 'Contact';

export const MAILCHIMP_NEWSLETTER_SIGNUP_URL =
    'https://facebook.us19.list-manage.com/subscribe/post?u=e6ba674afb67f249d88e5e307&id=f411bb7283';

export const MAILCHIMP_NEWSLETTER_EMAIL_NAME = 'EMAIL';

export const MAILCHIMP_NEWSLETTER_HONEYPOT_NAME =
    'b_e6ba674afb67f249d88e5e307_f411bb7283';
