import React from 'react';

import circularStdBlackWoff from '../assets/fonts/circular-std/CircularStd-Black.woff';
import circularStdBlackWoff2 from '../assets/fonts/circular-std/CircularStd-Black.woff2';
import circularStdBlackItalicWoff from '../assets/fonts/circular-std/CircularStd-BlackItalic.woff';
import circularStdBlackItalicWoff2 from '../assets/fonts/circular-std/CircularStd-BlackItalic.woff2';
import circularStdBoldWoff from '../assets/fonts/circular-std/CircularStd-Bold.woff';
import circularStdBoldWoff2 from '../assets/fonts/circular-std/CircularStd-Bold.woff2';
import circularStdBoldItalicWoff from '../assets/fonts/circular-std/CircularStd-BoldItalic.woff';
import circularStdBoldItalicWoff2 from '../assets/fonts/circular-std/CircularStd-BoldItalic.woff2';
import circularStdBookWoff from '../assets/fonts/circular-std/CircularStd-Book.woff';
import circularStdBookWoff2 from '../assets/fonts/circular-std/CircularStd-Book.woff2';
import circularStdBookItalicWoff from '../assets/fonts/circular-std/CircularStd-BookItalic.woff';
import circularStdBookItalicWoff2 from '../assets/fonts/circular-std/CircularStd-BookItalic.woff2';
import circularStdMediumWoff from '../assets/fonts/circular-std/CircularStd-Medium.woff';
import circularStdMediumWoff2 from '../assets/fonts/circular-std/CircularStd-Medium.woff2';
import circularStdMediumItalicWoff from '../assets/fonts/circular-std/CircularStd-MediumItalic.woff';
import circularStdMediumItalicWoff2 from '../assets/fonts/circular-std/CircularStd-MediumItalic.woff2';
import FontFace, { Font } from './FontFace';
import { FontFamily } from '../Settings';

const fonts: Font[] = [
    {
        fontFamily: FontFamily.CircularStd,
        woff: circularStdBlackWoff,
        woff2: circularStdBlackWoff2,
        fontStyle: 'normal',
        fontWeight: 800,
    },
    {
        fontFamily: FontFamily.CircularStd,
        woff: circularStdBlackItalicWoff,
        woff2: circularStdBlackItalicWoff2,
        fontStyle: 'italic',
        fontWeight: 800,
    },
    {
        fontFamily: FontFamily.CircularStd,
        woff: circularStdBoldWoff,
        woff2: circularStdBoldWoff2,
        fontStyle: 'normal',
        fontWeight: 600,
    },
    {
        fontFamily: FontFamily.CircularStd,
        woff: circularStdBoldItalicWoff,
        woff2: circularStdBoldItalicWoff2,
        fontStyle: 'italic',
        fontWeight: 600,
    },
    {
        fontFamily: FontFamily.CircularStd,
        woff: circularStdBookWoff,
        woff2: circularStdBookWoff2,
        fontStyle: 'normal',
        fontWeight: 400,
    },
    {
        fontFamily: FontFamily.CircularStd,
        woff: circularStdBookItalicWoff,
        woff2: circularStdBookItalicWoff2,
        fontStyle: 'italic',
        fontWeight: 400,
    },
    {
        fontFamily: FontFamily.CircularStd,
        woff: circularStdMediumWoff,
        woff2: circularStdMediumWoff2,
        fontStyle: 'normal',
        fontWeight: 500,
    },
    {
        fontFamily: FontFamily.CircularStd,
        woff: circularStdMediumItalicWoff,
        woff2: circularStdMediumItalicWoff2,
        fontStyle: 'italic',
        fontWeight: 500,
    },
];

function GlobalFonts(): React.ReactElement {
    return <FontFace fonts={fonts} />;
}

export default React.memo(GlobalFonts);
