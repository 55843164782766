// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-us-tsx": () => import("../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-contact-tsx": () => import("../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-forms-tsx": () => import("../src/pages/forms.tsx" /* webpackChunkName: "component---src-pages-forms-tsx" */),
  "component---src-pages-index-tsx": () => import("../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-resources-tsx": () => import("../src/pages/resources.tsx" /* webpackChunkName: "component---src-pages-resources-tsx" */)
}

