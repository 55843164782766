module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-153071694-1","anonymize":true,"respectDNT":true},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.jazzaotearoa.co.nz"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/build/repo/packages/www/src/components/Layout.tsx"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Jazz Aotearoa","short_name":"Jazz Aotearoa","start_url":"/","background_color":"#19998c","theme_color":"#243482","display":"standalone","icon":"/opt/build/repo/packages/www/static/favicon.png","crossOrigin":"use-credentials"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://ad7ac30a940e48b99a899715ace872bc@sentry.io/1774140","environment":"production","enabled":true,"release":"a3f47f38e989638715294e725be20945dd4d705f","whitelistUrls":["https://www.jazzaotearoa.co.nz"]},
    }]
