import React from 'react';
import { createGlobalStyle, GlobalStyleComponent } from 'styled-components';

export interface Font {
    fontFamily: string;
    fontStyle?: 'normal' | 'italic';
    fontWeight?: number;
    woff?: string;
    woff2?: string;
}

const getSrc = ({ woff, woff2 }: { woff?: string; woff2?: string }): string =>
    [
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        woff2 && `url('${woff2}') format('woff2')`,
        /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
        woff && `url('${woff}') format('woff')`,
    ]
        .filter(Boolean)
        .join(', ');

const getFontFace = ({
    fontFamily,
    fontStyle,
    fontWeight,
    woff,
    woff2,
}: Font): string => `@font-face {
  font-family: '${String(fontFamily)}';
  font-style: ${String(fontStyle)};
  font-weight: ${String(fontWeight)};
  src: ${getSrc({ woff, woff2 })};
}`;

const getFontFaceStack = (fonts: Font[]): string =>
    fonts.map((font: Font): string => getFontFace(font)).join('\n');

interface FontFaceProps {
    fonts: Font[];
}

const FontFace: React.FunctionComponent<FontFaceProps> = ({
    fonts,
}: FontFaceProps): React.ReactElement => {
    const Component = React.useMemo(
        (): GlobalStyleComponent<{}, {}> =>
            createGlobalStyle((): string => getFontFaceStack(fonts)),
        [fonts]
    );

    return <Component />;
};

export default React.memo(FontFace);
